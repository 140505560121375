<template>
    <div  class="global-content">
        <div class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-arrow-right global-title-fa"></i><span class="global-title">请选择您要操作的设备</span><span class="global-remark">（多选）</span></div>
            <van-divider />
            <div class="handle" v-if="devData.length != 0">
                <van-checkbox-group class="box-container" v-model="result" direction="horizontal" ref="checkboxGroup">
                    <van-col span="8" v-for="(item,index) in devData" :key="index">
                        <van-checkbox checked-color="#0957ff" :name="item.devCode" >{{item.devName}}</van-checkbox>
                    </van-col>
                </van-checkbox-group>
                <van-divider style="margin: 1% 0 5% 0" />
                <div class="layout">
                    <van-button color="#0957ff" round class="m-right" size="small" @click="checkAll">全选</van-button>
                    <van-button color="#0957ff" round size="small"  @click="toggleAll">反选</van-button>
                </div>
            </div>
            <div v-else class="empty-hint">{{hintText}}</div>
        </div>
        <div class="block global-shadow">
            <div><i class="fa fa-support global-title-fa"></i><span class="global-title">节目控制</span></div>
            <van-divider />
            <div class="info">
                <van-row>
                    <van-col span="6" v-for="(item,i) in programData" :key="item.i" @click="selectItem(item.code,i)" :class="configItemIndex == i? 'active':'' ">
                        <div><i class="fa fa-dashboard "></i></div>
                        <div class="text" v-text="item.name"></div>
                    </van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Program',
    data(){
        return{
            devData:[],
            programData:[],
            configItemIndex:0,
            isAisle:true,
            result: [],
            devArr:[],
            hintText:'加载中...'
        }
    },
    mounted(){
        // 获取所有设备信息
        const projectCode = window.sessionStorage.getItem('projectCode');
        this.$api.ITGS.getAllDevInfo({
            controlDevShow:1,
            projectCode:projectCode
        }).then((data)=>{
            if(Array.isArray(data)){
                data.length == 0 ?  this.hintText='具备功能的设备列表为空' : this.devData = data;        
            }
        })
        // 获取节目控制选项
        this.$api.ITGS.getProgramList({}).then((data)=>{
            this.programData = data;
        })
    },
    methods:{
        //全选
        checkAll() {this.$refs.checkboxGroup.toggleAll(true);},
        //反选
        toggleAll() {this.$refs.checkboxGroup.toggleAll();},
        // 多个设备控制
        selectItem(code,i){
            if(this.result.length == 0){
                this.$toast('请先选择要操作的设备');
                return false;
            } 
            if(this.isAisle){
                this.configItemIndex =i;
                this.isAisle = false;
                let progPath = this.programData.find( e => e.code == code).url;
                this.$api.ITGS.subProgramBatch({
                    devCode:this.result,
                    progCode:code,
                    progPath:progPath
                }).then(()=>{
                    this.$toast('节目单指令已下发');
                    let timeLimit = setTimeout(()=>{
                        this.isAisle = true;
                        clearTimeout(timeLimit)
                    },5000)
                })
            }else{
                this.$toast('操作频率过快，请在5秒之后再试')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.block{
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    .handle{
        padding: 0 4% 4% 4%;
        font-size: 0.95rem;
        .box-container{min-height:4rem;max-height:9.8rem;overflow-x: hidden;overflow-y: scroll;}
        .van-col{margin-bottom: 6%;}
        .layout{
            text-align: right;
            .m-right{margin-right:10%}
        }
    }
    .info{
        color:#656565;
        text-align: center;
        .active{color: #0957ff;}
        .line-block{text-align: left;padding-left: 5%;}
        .van-col{margin-bottom: 8%;}
        .fa{font-size: 1.5rem;vertical-align:middle}
        .text{font-size: .8rem;padding-top: 4px;height: 2rem;}
    }
}
</style>